<template lang="pug">
  include ../plugins/pug/btn

  section.section
    div.container
      div.partnership
        div.partnership__body.text-xl.align-self-center
          h2.h2.uppercase Process of <strong>Partnership</strong>
          p We guarantee to our clients a structured and transparent process for effective collaboration and successful project delivery.
          p In every step of the way, we prioritize the client's needs and business objectives to ensure we develop a product that not only meets but exceeds expectations. From initial conceptualization to the final delivery, our team follows best industry practices and leverages state-of-the-art technologies, ensuring the project's success. Our agile methodology allows us to accommodate changes and rapidly respond to emerging challenges, enhancing the overall project efficiency and the final outcome's quality.

        div.partnership__img
          div.img__wrapp
            img(
              :src="Partnership"
              alt="partnership image"
            )
</template>

<script>
// images
import Partnership from '@/assets/img/partnership.svg'
export default {
  name: 'PartnershipBlock',
  data () {
    return {
      Partnership
    }
  }
}
</script>
